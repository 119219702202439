<template>
  <el-dialog
    class="feedback_dialog"
    title="意见详情"
    :visible.sync="dialogVisible"
    :before-close="dialogBeforeClose"
    width="41.66667vw"
  >
    <div>
      <p>{{dialogData.info}}</p>
      <el-image
        v-for="img in dialogData.imgs"
        :key="img.id"
        :src="img.img"
        :fit="'fit'"
        :load="imageLoad"
        :preview-src-list="[img.img]"
      >
        <div slot="placeholder" class="image-slot" v-loading="imageLoading">
        </div>
      </el-image>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    feedbackHandleData: Object
  },
  data() {
    return {
      dialogVisible: false,
      dialogData: {},
      imageLoading: true
    };
  },
  methods: {
    dialogBeforeClose() {
      this.dialogVisible = false;
    },
    imageLoad() {
      console.log(11);
    }
  },
  watch: {
    feedbackHandleData(data) {
      if (data) {
        this.dialogVisible = true;
        this.dialogData = data;
      }

    }
  }
};
</script>

<style lang="scss">
.feedback_dialog {
  .el-dialog__body {
    min-height: 400px;
    .el-image {
      position: relative;
      margin: 10px;
      width: 355px;
      height: 250px;
      border: 1px solid #00000010;
      overflow: hidden;
      .image-slot {
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
}
.el-image-viewer__wrapper {
  .el-image-viewer__canvas {
    transform: scale(.8);
  }
}
</style>
