<template>
  <div class="course-wrapper"><!--v-loading="feedbackLoading"-->
    <div class="course-wrapper-title" >
      <span>意见反馈</span>
    </div>

    <div class="course-wrapper-body">
      <div class="course-wrapper-function">
        <div class="function-left">
          <el-input placeholder="用户姓名" class="input-search" v-model="search.keyword"></el-input>

          <el-select v-if="feedbackActiveName === '0'" placeholder="选择课程" v-model="search.course" class="input-select" @change="selectChangeCourse">
            <el-option v-for="(item, index) in dicts" :key="index" :label="item.name" :value="index"></el-option>
          </el-select>


          <el-date-picker type="date" placeholder="选择日期" v-model="search.time"></el-date-picker>
          <el-button @click="buttonSearch" type="primary" icon="el-icon-search">查询</el-button>

          <el-button @click="buttonReset" icon="el-icon-refresh-left">重置</el-button>
        </div>
      </div>

      <div class="feedback-handle">
        <el-radio-group v-model="feedbackActiveName" @change="feedbackHandleActive">
          <el-radio-button label="0">课程反馈</el-radio-button>
          <el-radio-button label="1">其他反馈</el-radio-button>
        </el-radio-group>
      </div>

      <el-table
        v-show="feedbackActiveName === '0'"
        :data="feedbackData"
        class="feedback-table"
        ><!--v-loading="tableLoading"-->
        <el-table-column label="序号" type="index" align="center" width="80"></el-table-column>
        <el-table-column prop="courseName" label="课程活动" align="center">
          <template slot-scope="scope">
            <el-button @click="jumpCourse(scope.row,'course')" type="text">{{ scope.row.courseName }}</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="courseThemeName" label="主题/活动名称" align="center">
          <template slot-scope="scope">
            <el-button @click="jumpCourse(scope.row,'courseContent')" type="text">{{ scope.row.courseThemeName }}</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="catalogueName" label="目录标题" align="center"></el-table-column>
        <el-table-column prop="info" label="反馈内容" align="center" width="80">
          <template slot-scope="scope">
            <el-button @click="handleDetails(scope)" type="text">查看</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="flag" label="角色" align="center" width="80">
          <template slot-scope="scope" >
            <el-tag v-if="scope.row.flag === '0'">家长</el-tag>
            <el-tag v-if="scope.row.flag === '1'">老师</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="用户姓名" align="center"></el-table-column>
        <el-table-column prop="createTime" label="反馈时间"></el-table-column>
      </el-table>

      <el-table
        v-show="feedbackActiveName === '1'"
        :data="feedbackData"
      ><!--v-loading="tableLoading"-->
        <el-table-column label="序号" type="index" align="center"></el-table-column>
       <!-- <el-table-column prop="type" label="类型" align="center">
          <template slot-scope="scope">
            <p v-show="scope.row.type == '0'">课程反馈</p>
            <p v-show="scope.row.type == '1'">功能反馈</p>
            <p v-show="scope.row.type == '2'">意见投诉</p>
          </template>
        </el-table-column> -->
		<el-table-column prop="typeName" label="类型" align="center"></el-table-column>
        <el-table-column prop="info" label="反馈内容" align="center" cell-class-name="handle-button">
          <template slot-scope="scope">
            <el-button @click="handleDetails(scope)" type="text">查看</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="falg" label="用户角色" align="center">
          <template slot-scope="scope" >
            <el-tag v-if="scope.row.flag === '0'">家长</el-tag>
            <el-tag v-if="scope.row.flag === '1'">老师</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="用户姓名" align="center"></el-table-column>
        <el-table-column prop="createTime" label="反馈时间" align="center"></el-table-column>
      </el-table>
    </div>

    <div class="course-wrapper-footer">
      <div class="footer-left">
        显示第{{(pagination.current - 1) * pagination.size + 1}}-{{(pagination.current - 1) * pagination.size + feedbackData.length}}条记录，共{{pagination.total}}条记录
      </div>
      <div class="footer-right">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-size="pagination.size"
          :total="pagination.total"
          layout="total,sizes,prev,pager,next,jumper"
          background></el-pagination>
      </div>
    </div>

    <feedback-details :feedbackHandleData="feedbackHandleData"></feedback-details>
  </div>
</template>

<script>
import utils from '@/utils/tool';
import feedbackDetails from '@/views/feedback/feedbackDetails';
export default {
  name: 'feedback',
  components: { feedbackDetails },
  data() {
    return {
      feedbackActiveName: '0', // 课程反馈 0，其他反馈 1
      feedbackData: [], // 反馈列表
      feedbackHandleData: {},
      dicts: [], // 课程字典列表
      search: { // 搜索参数
        keyword: '',
        course: '',
        courseId: '',
        time: '',
      },
      pagination: { // 页码
        current: 1,
        size: 10,
        total: 0
      },
      feedbackLoading: false,
      tableLoading: false, // 表格loading
      pageLoading: null, //页面loading
    };
  },
  created() {
    this.$api.getTechDict({ type: 'class_classify' })
      .then(res => {
        if (res.data.code) {
          this.$message.error(res.data.msg);
          return;
        }
        this.dicts = res.data.data;
      });
    // this.tableLoading = true;
    this.pageLoading = this.$loading({
      lock: true,
      fullscreen: true,
      text: '加载中',
      spinner: 'el-icon-loading',
      background: 'rgba(0, 0, 0, 0.7)',
      customClass: "loadingStyle"
    });
    this.queryFeedback();
  },
  methods: {
    /**
     * 查询反馈列表
     */
    queryFeedback() {
      let data = {};
      let { current, size } = this.pagination;
      data.flag = this.feedbackActiveName;
      if (current) {
        data.current = current;
      }
      if (size) {
        data.size = size;
      }
      if (this.feedbackActiveName === '0') {
        if (this.search.courseId) {
          data.courseId = this.search.courseId;
        }
      }
      if (this.search.time) {
        data.startTime = this.search.time;
        data.startTime = utils.formatTime(data.startTime);
        data.endTime = data.startTime;
        data.startTime += 'T00:00:00';
        data.endTime += 'T23:59:59';
      }
      if (this.search.keyword) {
        data.keyword = this.search.keyword;
      }
      console.log(data);
      this.$api.getTechfFeedback(data)
        .then(res => {
          // this.tableLoading = false;
          if (res.data.code) {
            this.$message.error(res.data.msg);
            return;
          }
          let { data } = res.data;
          this.feedbackData = data.records;
          this.pagination.total = data.total;
          this.pagination.pages = data.pages;
        })
        .catch(e => {
          console.log(e);
        })
        .finally(() => {
          this.pageLoading.close()
        });
    },
    handleDetails(data) {
      let { info, imgs } = data.row;
      this.feedbackHandleData = {
        info,
        imgs
      };
    },
    // 课程跳转
    jumpCourse(info, type) {
      if (type == 'course') {
        this.$router.push({ path: '/courseResource/courseIntro', query: { id: info.courseId, courseId: info.courseId } });
      } else if (type == 'courseContent') {
        this.$router.push({ path: '/courseResource/courseContent', query: { id: info.themeId, courseId: info.courseId } });
      }
    },
    feedbackHandleActive(activeName) {
      this.feedbackActiveName = activeName;
      this.pagination.current = 1;
      // this.tableLoading = true;
      this.pageLoading = this.$loading({
        lock: true,
        fullscreen: true,
        text: '加载中',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
        customClass: "loadingStyle"
      });
      this.queryFeedback();
    },
    handleSizeChange(val) {
      this.pagination.size = val;
      // this.tableLoading = true;
      this.pageLoading = this.$loading({
        lock: true,
        fullscreen: true,
        text: '加载中',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
        customClass: "loadingStyle"
      });
      this.queryFeedback();
    },
    handleCurrentChange(val) {
      this.pagination.current = val;
      // this.tableLoading = true;
      this.pageLoading = this.$loading({
        lock: true,
        fullscreen: true,
        text: '加载中',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
        customClass: "loadingStyle"
      });
      this.queryFeedback();
    },
    /**
     * 选择框-选择课程
     */
    selectChangeCourse(val) {
      this.search.courseId = this.dicts[val].id;
    },
    /**
     * 按钮-查询
     */
    buttonSearch() {
      // this.tableLoading = true;
      this.pageLoading = this.$loading({
        lock: true,
        fullscreen: true,
        text: '加载中',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
        customClass: "loadingStyle"
      });
      this.queryFeedback();
    },
    /**
     * 按钮-重置
     */
    buttonReset() {
      this.search.keyword = '';
      this.search.course = '';
      this.search.courseId = null;
      this.search.time = '';
      // this.tableLoading = true;
      this.pageLoading = this.$loading({
        lock: true,
        fullscreen: true,
        text: '加载中',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
        customClass: "loadingStyle"
      });
      this.queryFeedback();
    }
  }
};
</script>

<style lang="scss">
@import '@/assets/css/common_c.scss';
.feedback-handle {
  margin-bottom: 10px;
}
.course-wrapper-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .footer-left {
    color: #7c7c7c;
    font-size: 14px;
  }
}
</style>
